import axios from '@/axios/index'

// 选课-获取课程
export function getClass(data){
    return axios({
        url:'/api/app/v1/course/package/list',
        method:'post',
        data:data
    })
}

// 选课中心-获取详情1
export function packageDetail(data){
    return axios({
        url:'/api/app/v1/course/package/detail',
        method:'post',
        data:data
    })
}
// 选课中心-获取详情2 NEW
export function courseDetailByPackageId(data){
    return axios({
        url:'/api/app/v1/course/package/courseDetailByPackageId',
        method:'post',
        data:data
    })
}

// 选课中心-领取课程优惠券
export function courseCoupon(data){
    return axios({
        url:'/api/app/v1/course/package/receive/course/coupon',
        method:'post',
        data:data
    })
}

// 选课中心-立即报名按钮-生成订单
export function createOrder(data){
    return axios({
        url:'/api/app/v1/course/order/get/order',
        method:'post',
        data:data
    })
}

// 视频播放页  获取playsafe
export function playerSafe(data){
    return axios({
        url:'/api/pc/v1/common/playerSafe',
        method:'post',
        data:data
    })
}

// 视频播放页  获取讲义
export function courseware(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/courseware',
        method:'post',
        data:data
    })
}

// 视频播放页  视频进度上报
export function planReportedData(data){
    return axios({
        url:'/api/app/v1/course/package/receive/course/planReportedData',
        method:'post',
        data:data
    })
}

// 视频播放页  获取笔记
export function noteList(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/classhour/note/list',
        method:'post',
        data:data
    })
}

// 视频播放页  新增笔记
export function noteAdd(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/classhour/note/add',
        method:'post',
        data:data
    })
}

// 视频播放页  修改笔记
export function noteEdit(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/classhour/note/edit',
        method:'post',
        data:data
    })
}

// 视频播放页  下载视频 已废弃
export function downloadCount(data){
    return axios({
        url:'/api/pc/v1/material/add/download/count',
        method:'post',
        data:data
    })
}


// 视频播放页  下载视频 
export function getLiveToken(data){
    return axios({
        url:'https://api.polyv.net/live/v3/channel/common/get-chat-token',
        method:'post',
        data:data
    })
}


//课程直播答疑  获取答疑列表
export function dayiList(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/dayi/list',
        method:'post',
        data:data
    })
}

//课程直播答疑  课程答疑
export function myDaYi(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/mine/course/dayi/list',
        method:'post',
        data:data
    })
}

// 答疑 上传图片
export function setUpload(files){
    return axios({
        url:'/api/pc/alioss/uploadImage',
        method:'post',
        data:files
    })
}

//添加答疑
export function dayiAdd(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/dayi/add',
        method:'post',
        data:data
    })
}

//获取视频回放
export function playback(data){
    return axios({
        url:'/api/app/v1/live/playback',
        method:'post',
        data:data
    })
}


//根据类型获取直播信息
export function playbackByChannelId(data){
    return axios({
        url:'/api/app/v1/live/playbackByChannelId',
        method:'post',
        data:data
    })
}

//根据类型获取直播信息
export function getliveModeByChannelAndType(data){
    return axios({
        url:'/api/app/v1/live/getliveModeByChannelAndType',
        method:'post',
        data:data
    })
}


//获取学习课时状态
export function watchLive(data){
    return axios({
        url:'/api/app/v1/live/watchLive',
        method:'post',
        data:data
    })
}

//直播是否购买
export function live_isBuy(data){
    return axios({
        url:'/api/pc/v1/live/isBuy',
        method:'post',
        data:data
    })
}

//回放视频上报
export function playbackReportedData(data){
    return axios({
        url:'/api/app/v1/course/package/playback/course/playbackReportedData',
        method:'post',
        data:data
    })
}