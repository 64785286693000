import request from '@/axios/index';

//  修改默认地址
export function couponlist(query) {
    return request({
        url: '/api/app/v1/mine/coupon/list',
        method: 'post',
        data: query
    })
}
//  获取课程协议
export function getAgressment(query) {
    return request({
        url: '/api/app/v1/h5/course/purchase/agreement?coursePurchaseAgreementId='+query,
        method: 'get',
    })
}
//  支付
export function pay(query) {
    return request({    
        url: '/api/h5/v1/course/order/course/package/pay',
        method: 'post',
        data: query
    })
}
//  支付
export function orderstatus(query) {
    return request({    
        url: '/api/pc/v1/pay/get/order/status',
        method: 'post',
        data: query
    })
}
// 直播获取订单详情
export function liveorder(query) {
    return request({    
        url: '/api/app/v1/live/gen/order',
        method: 'post',
        data: query
    })
}
// 直播获取订单详情
export function getOpenId(query) {
    return request({    
        url: '/api/h5/v1/wechat/get/open/id',
        method: 'post',
        data: query
    })
}
// home、homeLiveList 购买直播
export function h5LivePay(data) {
    return request({
        url: '/api/h5/v1/course/order/live/pay',
        method: 'post',
        data: data
    })
}

// h5生产订单-新
export function chaifeiOrder(data){
    return request({
        url:'/api/h5/v1/course/order/course/package/order',
        method:'post',
        data:data
    })
}

// h5支付-新
export function chaifeiPay(data){
    return request({
        url:'/api/h5/v1/course/order/course/package/h5/pay',
        method:'post',
        data:data
    })
}

export function getOpenIdByUserId(userId) {
    return request({
        url: '/api/h5/v1/wechat/get/wx/official/account/code?userId='+userId,
        method: 'get',
    })
}