//封装微信登录接口
export const wxLogin = (appid, url) => {
	let redirect_uri = encodeURIComponent(url)
	window.location.href =
		`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`
}

// 获取url上的参数
export const getUrlParam = (name) => {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
	let r = window.location.search.substr(1).match(reg); //匹配目标参数
	if (r != null) return decodeURIComponent(r[2]);
	return null; //返回参数值　
}
// 判断是否是在微信中打开
export const is_weixin = () => {
	var ua = navigator.userAgent.toLowerCase();
	if(ua.match(/MicroMessenger/i)=="micromessenger") {
		return true;
	} else {
		return false;
	}
 }

// 时间戳 转 年月日
export function filterTime(time) {
    const date = new Date(time)
    const Y = date.getFullYear()
    const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 
    const D = date.getDate()
    return `${Y}年${M}月${D}日`
}

// 时间转化  秒转时分秒
export function getHMS(time) {
    // 转换为式分秒
    let h = parseInt(time / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
    let m = parseInt(time / 60 % 60)
    m = m < 10 ? '0' + m : m
    let s = parseInt(time % 60)
    s = s < 10 ? '0' + s : s
    // 作为返回值返回
    return h+'时'+m+'分'+s+'秒'
}

// 字符串加密/解密  用于隐藏路径参数 
// 加密：var code = kbt.encrypt('我爱北京天安门'); console.log(code)
// 解密：var str =  kbt.decrypt(code); console.log(str)
export let kbt = {
    // 加密
    encrypt (value) {
        var keys = "abcdefghijklmnopqrstuvwxyz12345678";
        var kleng = keys.length;
        var kstr = keys.split("");
        var v = "",cat, cat1, cat2, cat3;
        for (var i = 0; i <value.length; i ++) {
            cat = value.charCodeAt(i);
            cat1 = cat % kleng;
            cat = (cat - cat1) / kleng;
            cat2 = cat % kleng;
            cat = (cat - cat2) / kleng;
            cat3 = cat % kleng;
            v += kstr[cat3] + kstr[cat2] + kstr[cat1];
        }
        return v;
    },
    // 解密
    decrypt(value) {
        var keys = "abcdefghijklmnopqrstuvwxyz12345678";
        var kleng = keys.length;
        var alen, cat1, cat2, cat3, num = 0, arr;
        arr = new Array(Math.floor(value.length / 3));
        alen = arr.length;
        for (var i = 0; i < alen; i ++) {
            cat1 = keys.indexOf(value.charAt(num));
            num ++;
            cat2 = keys.indexOf(value.charAt(num));
            num ++;
            cat3 = keys.indexOf(value.charAt(num));
            num ++;
            arr[i] = cat1 * kleng * kleng + cat2 * kleng + cat3
        }
        alen = eval("String.fromCharCode(" + arr.join(',') + ")");
        return alen;
    }
}
