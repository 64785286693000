<template>
    <div class="orderdetail">
        <!-- 头部 -->
        <div class="header">
            <div class="left">
                <van-icon name="arrow-left" class="icon" @click="gopath('back')" />
            </div>
            <div class="title">订单信息</div>
        </div>
        <!-- 订单 -->
        <div class="order">
            <!-- 套餐 -->
            <div class="adr" v-if="orderType == 0 && isTextbook == 1" @click="gopath('/address')">
                <div class="left">
                    <img src="@/assets/all_Icon/drawable-xxhdpi/icon_address.png" class="img" />
                </div>
                <div class="user" v-if="orderInfo.orderAddress">
                    <div class="top">
                        <div class="name">{{ orderInfo.orderAddress.name }}</div>
                        <div class="phone">{{ orderInfo.orderAddress.mobile }}</div>
                    </div>
                    <div class="address">
                        {{ orderInfo.orderAddress.province }}{{ orderInfo.orderAddress.city
                            }}{{ orderInfo.orderAddress.area
                            }}{{ orderInfo.orderAddress.address }}
                    </div>
                </div>
                <div class="user" v-else>请填写您的地址</div>
                <div class="icon">
                    <van-icon name="arrow" />
                </div>
            </div>

            <div class="course">
                <!-- 套餐 -->
                <div class="top" v-if="orderType == 0">
                    <div class="left">
                        <img :src="
                        orderInfo &&
                        orderInfo.orderCoursePackageInfo &&
                        orderInfo.orderCoursePackageInfo.coverPath ? orderInfo.orderCoursePackageInfo.coverPath : ''" class="img" />
                    </div>
                    <div class="right">
                        <div class="course_title">
                            {{ orderInfo.orderCoursePackageInfo.coursePackageName }}
                        </div>
                        <!-- talon??? -->
                        <div class="pirce">
                            ¥{{ orderInfo.orderCoursePackageInfo.price }}
                        </div>
                    </div>
                </div>
                <!-- 直播 -->
                <div class="liveInfo">
                    <!-- <div class="live_key">课程信息</div>
                    <div class="live_box">
                        <span class="live_title">{{ liveData.name }}</span>
                        <span class="live_price">¥{{ liveData.price }}</span>
                    </div> -->
                </div>
                <div class="bottom">
                    <div class="validity item" v-if="orderType == 0">
                        <div class="item_title">有效期</div>
                        <div class="value">
                            <span v-if="orderInfo.orderCoursePackageInfo.effectiveMonth">
                                {{ orderInfo.orderCoursePackageInfo.effectiveMonth }} 月
                            </span>
                            <span v-if="orderInfo.orderCoursePackageInfo.effectiveEtime">{{
                                filterTime(orderInfo.orderCoursePackageInfo.effectiveEtime)
                              }}</span>
                        </div>
                    </div>
                    <div class="validity item" v-if="orderType == 1">
                        <div class="item_title">直播时间</div>
                        <div class="value">
                            {{ liveData.liveTime }}
                        </div>
                    </div>
                    <div class="coupon item">
                        <div class="item_title">优惠券</div>
                        <div class="value" v-if="orderType == 0" @click="gopath('/coupon')">
                            {{ orderInfo.orderCoursePackageInfo.couponPrice }}
                            <van-icon name="arrow" />
                        </div>
                        <div class="value" v-if="orderType == 1">
                            {{ liveData.couponPrice }}
                        </div>
                    </div>
                    <div class="gift item" v-if="orderType == 0">
                        <div class="item_title">
                            <span>赠品</span>
                            <img src="@/assets/all_Icon/drawable-xxhdpi/my_gift.png" class="img" />
                        </div>
                        <div class="value">
                            <!-- <span v-for="(item,index) in orderInfo.orderCoursePackageInfo.giveCourseNames" :key="index">{{item}}</span> -->
                            {{ orderInfo.orderCoursePackageInfo.giveCourseNames[0] }}
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="liveInfo" v-if="orderType == 1"></div>
            <div class="price">
                <div class="item">
                    <div class="item_title">总金额</div>
                    <div class="value" v-if="orderType == 0">
                        ¥{{ orderInfo.totalPrice }}
                    </div>
                    <div class="value" v-if="orderType == 1">
                        ¥{{ liveData.totalPrice }}
                    </div>
                </div>
                <div class="item">
                    <div class="item_title">总优惠</div>
                    <div class="value" v-if="orderType == 0">
                        {{
                      orderInfo.orderCoursePackageInfo.couponPrice
                        ? orderInfo.orderCoursePackageInfo.couponPrice
                        : 0
                    }}
                    </div>
                    <div class="value" v-if="orderType == 1">
                        {{ liveData.couponPrice ? liveData.couponPrice : 0 }}
                    </div>
                </div>
                <div class="item">
                    <div class="item_title">
                        积分抵扣可抵扣¥{{ orderInfo.pointDeductionPrice }}
                        <img src="../../assets/all_Icon/drawable-xhdpi/icon_jifen.png" class="img" />
                    </div>
                    <div class="value">
                        <van-checkbox v-if="orderType == 0" :disabled="orderInfo.pointDeductionPrice < 1" v-model="checked"></van-checkbox>
                        <van-checkbox v-if="orderType == 1" :disabled="liveData.integralPrice < 1" v-model="livechecked"></van-checkbox>
                    </div>
                </div>
            </div>
            <div class="select_type">
                <div class="title">选择支付渠道</div>
                <div class="list">
                    <van-radio-group v-model="paymentType" class="item" v-for="(item, index) in paymentList" :key="index" v-if="item.show">
                        <div class="left">
                            <img :src="item.img" class="img" />
                            <div class="name">{{ item.title }}</div>
                        </div>
                        <div class="right">
                            <van-radio :name="item.type"></van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <div class="userAgreement">
                <van-checkbox v-model="agreementCheck" shape="square">
                    <span>用户服务协议</span>
                </van-checkbox>
                <span class="agreement" @click="
            goagreement(
              orderType == 0
                ? orderInfo.orderCoursePackageInfo.coursePackageId
                : liveData.coursePurchaseAgreementId
            )
          ">{{
            orderType == 0
              ? orderInfo.orderCoursePackageInfo.coursePurchaseAgreementName
              : liveData.coursePurchaseAgreementName
          }}</span>
            </div>
        </div>
        <!-- 支付 -->
        <div class="pay">
            <div class="left">
                <span class="all">合计：</span>
                <span class="price" v-if="orderType == 0">¥
                    {{
            orderInfo.orderCoursePackageInfo.price -
            orderInfo.orderCoursePackageInfo.couponPrice
          }}</span>
                <span v-else class="price">
                    {{ liveData.totalPrice }}
                </span>
            </div>
            <div class="right">
                <van-button @click="pay" type="info">立即支付</van-button>
            </div>
        </div>
        <div class="alipaysubmit" v-html="alipayfrom"></div>
    </div>
</template>

<script>
import { get } from '@/utils/ajax.js';
import { filterTime } from "@/utils/utils.js";
import { createOrder } from "@/api/selectClass.js";
import {
    pay,
    getAgressment,
    liveorder,
    getOpenId,
    h5LivePay,
    chaifeiOrder,
    chaifeiPay,
    getOpenIdByUserId
} from "@/api/order.js";
function getUrlKey(name) {    
    //获取url 参数
    return (
        decodeURIComponent(
            (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                location.href
            ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
    );
}

function getCodeApi(state) {
    //获取code
    let urlNow = encodeURIComponent(window.location.href);
    let scope = "snsapi_base"; //snsapi_userinfo //静默授权 用户无感知
    //let appid = "wxaca9d08ae096883e";
    let appid = "wx7f4b3784986dcb47";
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    window.location.replace(url);
}
export default {
    props: {},
    data() {
        return {
            coursePackageId: "", //课程套餐id
            coursePackagePriceId: "", //课程套餐价格id
            isTextbook: "", // 是否需要地址
            liveId: "", // 直播ID
            orderInfo: {
                orderCoursePackageInfo: {
                    coverPath: "",
                    coursePackageName: "",
                    giveCourseNames: [],
                    couponPrice: "",
                },
                orderAddress: {
                    name: "",
                    mobile: "",
                    province: "",
                    city: "",
                    area: "",
                    address: "",
                },
            }, //获取到的订单信息
            liveData: {
                liveId: 7,
                price: "1",
                couponPrice: "0",
                totalPrice: "1",
                integralPrice: "0",
                name: "会计的六大法宝（一）",
                liveTime: "2022-09-09 15:00-16:00",
                couponId: null,
                coursePurchaseAgreementId: 9,
                coursePurchaseAgreementName: "《课程购买协议》",
            },
            checked: "", //套餐
            livechecked: "", //  直播
            paymentList: [
                {
                    title: "微信支付",
                    img: require("../../assets/all_Icon/drawable-xhdpi/icon_wxpay.png"),
                    type: 1,
                    show: true
                },
                {
                    title: "支付宝支付",
                    img: require("../../assets/all_Icon/drawable-xhdpi/icon_alipay.png"),
                    type: 2,
                    show: !this.isWeixin()
                },
            ],
            paymentType: 1,
            agreementCheck: false,
            alipayfrom: null,
            orderType: "", //  0 套餐  1 直播
            from: "",
            to: "",
            openId: "",
            code: "空",
        };
    },
    created() {
        if (window.location.href.indexOf("code") > -1) {
            if (this.isWeixin()) {
                let that = this;
                that.code = getUrlKey("code");
                if (that.code) {
                    let data = new FormData();
                    data.append("code", that.code);
                    data.append(
                        "userId",
                        JSON.parse(window.localStorage.getItem("userInfo")).data.id
                    );
                    getOpenId(data).then((res) => {
                        that.$set(that, "openId", res.data.data.openId);
                        // that.openId =  res.data.openId;
                    });
                }
            }
        } else {
            if (this.isWeixin()) {
                getCodeApi(123);
                //this.getWeiXinUrl();
            }
            return;
        }
    },
    mounted() {
        if (this.$route.query.coursePackageId) {
            this.coursePackageId = this.$route.query.coursePackageId;
            this.coursePackagePriceId = this.$route.query.coursePackagePriceId;
            this.isTextbook = this.$route.query.isTextbook;
            this.orderType = 0;
        }
        if (this.$route.query.liveId) {
            this.liveId = this.$route.query.liveId;
            this.orderType = 1;
        }
        if (window.sessionStorage.getItem("orderInfo") == null) {
            if (this.orderType == 0) {
                this.createOrder(); //生成订单信息
            } else {
                this.liveorder();
            }
        } else {
            this.orderInfo = JSON.parse(window.sessionStorage.getItem("orderInfo"));
            if (sessionStorage.getItem("addressFlag") == "true") {
                this.orderInfo.orderAddress = JSON.parse(
                    window.sessionStorage.getItem("addressObj")
                );
                this.orderInfo.orderAddress.orderAddressId =
                    this.orderInfo.orderAddress.id;
            }
            if (sessionStorage.getItem("couponFlag") == "true") {
                let couponObj = JSON.parse(window.sessionStorage.getItem("couponObj"));

                this.orderInfo.orderCoursePackageInfo.couponId =
                    couponObj.courseCouponId;
                this.orderInfo.orderCoursePackageInfo.couponPrice = couponObj.price;
                // this.orderInfo.orderAddress = JSON.parse(
                //   window.sessionStorage.getItem("addressObj")
                // );
            }
        }
    },
    methods: {
        getOpenIdFun(url){
            console.log("url",url);
            get(url, function (result) {
                console.log("result",result);
                let datas = JSON.parse(result);
                
            })
        },
        getWeiXinUrl(){
            let that = this;
            let data = JSON.parse(localStorage.getItem("userInfo"));
            console.log("userInfo",data);
            getOpenIdByUserId(data.data.id).then((res)=>{
                console.log("res",res);
                that.getOpenIdFun(res.data);
                //window.location.replace(res.data);
                

            });
        },
        isWeixin() {
            var ua = window.navigator.userAgent.toLowerCase();
            if (
                ua.match(/MicroMessenger/i) == "micromessenger" ||
                ua.match(/_SQ_/i) == "_sq_"
            ) {
                return true;
            } else {
                return false;
            }
        },
        // // 获取订单信息   套餐
        createOrder() {
            let data = new FormData();
            data.append("coursePackageId", this.coursePackageId);
            data.append("coursePackagePriceId", this.coursePackagePriceId);
            createOrder(data).then((res) => {
                console.log("生成订单---", res);
                if (res.data.code == 0) {
                    this.orderInfo = res.data.data;
                    window.sessionStorage.setItem(
                        "orderInfo",
                        JSON.stringify(this.orderInfo)
                    );
                } else {
                    this.$toast(res.data.msg);
                }
            });
        },
        // 时间戳 转 年月日
        filterTime(time) {
            const date = new Date(time);
            const Y = date.getFullYear();
            const M =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            const D = date.getDate();
            return `${Y}年${M}月${D}日`;
        },
        // 获取直播订单 信息
        liveorder() {
            let data = new FormData();
            data.append("liveId", this.liveId);
            liveorder(data).then((res) => {
                this.liveData = res.data.data;
            });
        },
        // 课程协议
        goagreement(val) {
            this.$router.push({ path: "/order/agreement?coursePackageId=" + val });
        },
        // 跳转路径
        gopath(path) {
            if (path == "back") {
                sessionStorage.removeItem("orderInfo");
                sessionStorage.removeItem("addressFlag");
                sessionStorage.removeItem("couponFlag");
                this.$router.replace({ path: `/ke/${this.coursePackageId}` });
            } else {
                if (path == "/coupon") {
                    this.$router.push({
                        path: path,
                        query: { coursePackageId: this.coursePackageId },
                    });
                } else if (path == "/orderstate") {
                    this.$router.push({
                        path: path,
                        query: {
                            serviceId: window.sessionStorage.getItem("serviceId"),
                            serviceType: window.sessionStorage.getItem("serviceType"),
                        },
                    });
                } else {
                    this.$router.push({ path: path });
                }
            }
        },
        // 支付
        pay() {
            if (!this.agreementCheck) {
                this.$toast("请仔细阅读《课程协议》");
                return;
            }
            var that = this;
            window.sessionStorage.setItem("orderpayFlag", "2");
            if (this.orderType == 0) {
                // 套餐支付
                let data = new FormData();
                data.append("coursePackageId", this.coursePackageId);  //1
                data.append("coursePackagePriceId", this.coursePackagePriceId);  //1
                
                data.append("isPointDeduction", Number(0)); //1
                data.append("source", "h5"); //1
                data.append(
                    "couponId",
                    this.orderInfo.orderCoursePackageInfo.couponId
                        ? Number(this.orderInfo.orderCoursePackageInfo.couponId)
                        : ""
                );
                if (this.isTextbook == 1) {
                    if (!this.orderInfo.orderAddress) {
                        this.$toast("该套餐包含教材，请选择收货地址");
                        return;
                    }
                    data.append(
                        "orderAddressId",
                        this.orderInfo.orderAddress.orderAddressId
                            ? this.orderInfo.orderAddress.orderAddressId
                            : ""
                    );
                }
                chaifeiOrder(data).then((result)=>{
                    if(result.data.code == 0){
                        let payData = new FormData();
                        payData.append("orderId", result.data.data.id);
                        payData.append("payWay", this.paymentType == 1 ? "微信" : "支付宝");
                        if (this.isWeixin()) {
                            payData.append("payWeChatType", 1);
                            payData.append("openid", this.openId);
                        } else {
                            payData.append("payWeChatType", 2);
                        }
                        chaifeiPay(payData).then((res) => {
                            if (this.isWeixin()) {
                                WeixinJSBridge.invoke(
                                    "getBrandWCPayRequest",
                                    {
                                        //appId: "wxaca9d08ae096883e",
                                        appId: "wx7f4b3784986dcb47",
                                        timeStamp: res.data.data.timeStamp,
                                        nonceStr: res.data.data.nonce_str,
                                        package: "prepay_id=" + res.data.data.prepay_id,
                                        signType: "MD5",
                                        paySign: res.data.data.paySign,
                                    },
                                    function (res) {
                                        console.log('zhifu,res',res);
                                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                                            that.$router.push({
                                                path: "/orderstate",
                                            });
                                        } else {
                                            this.$toast("微信支付失败");
                                        }
                                    }
                                );
                            } else {
                                this.payload(res);
                            }
                        });



                    }else{
                        this.$toast(result.data.msg);
                    }
                    
                })


                
                
            } else {
                // 直播支付
                let data = new FormData();
                data.append("couponPrice", this.liveData.couponPrice);
                data.append("integralPrice", this.liveData.integralPrice);
                data.append("isIntegral", 0);
                data.append("liveId", this.liveData.liveId);
                data.append("payPrice", this.liveData.totalPrice);
                data.append("payWay", this.paymentType == 1 ? "微信" : "支付宝");
                if (this.isWeixin()) {
                    data.append("payWeChatType", 1);
                    data.append("openid", this.openId);
                } else {
                    data.append("payWeChatType", 2);
                }
                h5LivePay(data).then((res) => {
                    this.payload(res);
                });
            }
        },
        payload(res) {
            window.sessionStorage.setItem("orderpayFlag", "1");
            window.sessionStorage.setItem("serviceId", res.data.data.serviceId);
            window.sessionStorage.setItem("serviceType", res.data.data.serviceType);
            if (this.paymentType == 1) {
                window.location.href = res.data.data.mweb_url;
            } else {
                this.alipayfrom = res.data.data.aliSign;
                this.$nextTick(() => {
                    document.forms["punchout_form"].submit();
                });
            }
        },
    },
    // 路由监听
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // vm相当于上面的this
            console.log(from.path);
            if (from.path.indexOf("/ke") > -1) {
                sessionStorage.removeItem("orderInfo");
                sessionStorage.removeItem("addressFlag");
                sessionStorage.removeItem("couponFlag");
            }
            vm.from = from.path;
            vm.to = to.path;
            if (from.path == "/" && sessionStorage.getItem("orderpayFlag") == "1") {
                vm.gopath("/orderstate");
            }
            vm.fromPage = from.path;
        });
    },
    beforeDestroy() {
        console.log(this.from, this.to, 33333333);
        // sessionStorage.removeItem("addressFlag");
        // sessionStorage.removeItem("couponFlag");
    },
};
</script>

<style lang="less" scoped>
.orderdetail {
    .header {
        padding: 10px 10px;
        display: flex;
        .left {
            width: 10%;
            .icon {
                font-size: 24px;
                font-weight: 550;
            }
        }
        .title {
            width: 80%;
            text-align: center;
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }
    }
    .order {
        background: #f8f8f8;
        padding: 18px 14px;
        .adr {
            background: #fff;
            padding: 20px 11px 20px 14px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            .left {
                width: 15%;
                .img {
                    width: 33px;
                }
            }
            .user {
                width: 80%;
                .top {
                    display: flex;
                    align-items: self-end;
                    margin-bottom: 6px;
                    .name {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 550;
                        color: #141414;
                        line-height: 23px;
                    }
                    .phone {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-left: 9px;
                    }
                }
                .address {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #141414;
                    line-height: 17px;
                }
            }
            .icon {
                width: 5%;
                .van-icon {
                    color: #b4b4b4;
                    font-size: 20px;
                }
            }
        }
        .course {
            background: #fff;
            padding: 20px 11px 20px 14px;
            border-radius: 5px;
            margin-top: 10px;
            .top {
                display: flex;
                .left {
                    margin-right: 13px;
                    .img {
                        width: 103px;
                    }
                }
                // talon???
                .right { 
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    // align-items: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    .course_title {
                        color: #141414;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }
                    .pirce {
                        color: #e91e14;
                    }
                }
            }
            .liveInfo {
                .live_key {
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 14px;
                }
                .live_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 500;

                    .live_title {
                    }
                    .live_price {
                        color: red;
                    }
                }
            }
            .bottom {
                border-top: 1px solid #dddddd;
                margin-top: 18px;
                padding-top: 15px;
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 14px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .item_title {
                        color: #333333;
                        display: flex;
                        align-items: center;
                        .img {
                            width: 14px;
                            margin-left: 5px;
                        }
                    }
                    .value {
                        color: #666666;
                    }
                }
                .coupon {
                    .value {
                        color: #d1323b;
                    }
                }
            }
        }
        .price {
            background: #fff;
            padding: 20px 11px 20px 14px;
            border-radius: 5px;
            margin-top: 10px;
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
                .item_title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    .img {
                        width: 14px;
                        height: 14px;
                    }
                }
                .value {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #d1323b;
                }
            }
        }
        .select_type {
            background: #fff;
            padding: 20px 11px 20px 14px;
            border-radius: 5px;
            margin-top: 10px;
            .title {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 550;
                color: #333333;
                line-height: 19px;
            }
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 11px;
                .left {
                    display: flex;
                    align-items: center;
                    .img {
                        width: 14px;
                    }
                    .name {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 19px;
                        margin-left: 3px;
                    }
                }
            }
        }
        .userAgreement {
            margin-top: 10px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 15px;
            display: flex;
            align-items: center;
            .agreement {
                color: #5d7dff;
            }
            /deep/ .van-checkbox__icon {
                font-size: 14px;
            }
        }
    }
    .pay {
        background: #fff;
        padding: 10px 17px 29px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            .all {
                color: #333333;
            }
            .price {
                color: #d1323b;
            }
        }
        .right {
            .van-button {
                width: 134px;
                height: 36px;
                line-height: 36px;
                border-radius: 5px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
            }
        }
    }
}
.alipaysubmit {
    display: none;
}
</style>